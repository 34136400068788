body,
html {
  height: 100%;
  scroll-behavior: smooth;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

.blur {
  filter: blur(4px);
}

/* NEW */

/* ############### FONTS IMPORT ############### */

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-BoldItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-ExtraLight.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Italic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Light.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-LightItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Medium.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-MediumItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-SemiBold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Text.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-TextItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Thin.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("/fonts/ibm-plex-sans/complete/woff/IBMPlexSans-ThinItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("/fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE;
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/inter-ui/Inter-UI-Thin-BETA.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-Thin-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 100;
  src: url("/fonts/inter-ui/Inter-UI-ThinItalic-BETA.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-ThinItalic-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/inter-ui/Inter-UI-ExtraLight-BETA.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-ExtraLight-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 200;
  src: url("/fonts/inter-ui/Inter-UI-ExtraLightItalic-BETA.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-ExtraLightItalic-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/inter-ui/Inter-UI-Light-BETA.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-Light-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/inter-ui/Inter-UI-LightItalic-BETA.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-LightItalic-BETA.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/inter-ui/Inter-UI-Regular.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/inter-ui/Inter-UI-Italic.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-Italic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/inter-ui/Inter-UI-Medium.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-Medium.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 500;
  src: url("/fonts/inter-ui/Inter-UI-MediumItalic.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/inter-ui/Inter-UI-SemiBold.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-SemiBold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 600;
  src: url("/fonts/inter-ui/Inter-UI-SemiBoldItalic.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/inter-ui/Inter-UI-Bold.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-Bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/inter-ui/Inter-UI-BoldItalic.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-BoldItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/inter-ui/Inter-UI-ExtraBold.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-ExtraBold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 800;
  src: url("/fonts/inter-ui/Inter-UI-ExtraBoldItalic.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/inter-ui/Inter-UI-Black.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-Black.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI';
  font-style: italic;
  font-weight: 900;
  src: url("/fonts/inter-ui/Inter-UI-BlackItalic.woff2") format("woff2"), url("/fonts/inter-ui/Inter-UI-BlackItalic.woff") format("woff");
}

/* --------------------------------------------------------------------------
  Single variable font.
  
  Note that you may want to do something like this to make sure you're serving
  constant fonts to older browsers:
  html {
    font-family: 'Inter UI', sans-serif;
  }
  @supports (font-variation-settings: normal) {
    html {
      font-family: 'Inter UI var', sans-serif;
    }
  }
  
  /* BUGS:
  - Safari 12.0 will default to italic instead of regular when font-weight
    is provided in a @font-face declaration.
    Workaround: Use "Inter UI var alt" for Safari, or explicitly set
    `font-variation-settings:"slnt" DEGREE`.
  */
@font-face {
  font-display: swap;
  font-family: 'Inter UI var';
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  src: url("/fonts/inter-ui/Inter-UI.var.woff2") format("woff2-variations"),
    url("/fonts/inter-ui/Inter-UI.var.woff2") format("woff2");
}

/* --------------------------------------------------------------------------
  
  "Inter UI var alt" is recommended for Safari and Edge, for reliable italics.
  
  @supports (font-variation-settings: normal) {
    html {
      font-family: 'Inter UI var alt', sans-serif;
    }
  }
  
  */
@font-face {
  font-display: swap;
  font-family: 'Inter UI var alt';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("/fonts/inter-ui/Inter-UI-upright.var.woff2") format("woff2 supports variations(gvar)"), url("/fonts/inter-ui/Inter-UI-upright.var.woff2") format("woff2-variations"), url("/fonts/inter-ui/Inter-UI-upright.var.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: 'Inter UI var alt';
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url("/fonts/inter-ui/Inter-UI-italic.var.woff2") format("woff2 supports variations(gvar)"), url("/fonts/inter-ui/Inter-UI-italic.var.woff2") format("woff2-variations"), url("/fonts/inter-ui/Inter-UI-italic.var.woff2") format("woff2");
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #1b2e4b;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 500;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #0168fa;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0148ae;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

a.no-style {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #7987a1;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.25;
  color: #1b2e4b;
}

h1,
.h1 {
  font-size: 2.1875rem;
}

h2,
.h2 {
  font-size: 1.75rem;
}

h3,
.h3 {
  font-size: 1.53125rem;
}

h4,
.h4 {
  font-size: 1.3125rem;
}

h5,
.h5 {
  font-size: 1.09375rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

hr {
  border-color: rgba(72, 94, 144, .16);
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.9375rem + 2px);
  padding: 0.46875rem 0.625rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  /* color: #596882; */
  color: #1b2e4b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus {
  color: #596882;
  background-color: #fff;
  border-color: #7cb2fe;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(1 104 250 / 25%);
}

/* Input error state */
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.9375rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.23438rem);
  background-size: calc(0.75em + 0.46875rem) calc(0.75em + 0.46875rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip {
  display: block;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

/* tooltip */

.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0
}

[data-tippy-root] {
  max-width: calc(100vw - 10px)
}

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity
}

.tippy-box[data-placement^=top]>.tippy-arrow {
  bottom: 0
}

.tippy-box[data-placement^=top]>.tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-placement^=bottom]>.tippy-arrow {
  top: 0
}

.tippy-box[data-placement^=bottom]>.tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom
}

.tippy-box[data-placement^=left]>.tippy-arrow {
  right: 0
}

.tippy-box[data-placement^=left]>.tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left
}

.tippy-box[data-placement^=right]>.tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^=right]>.tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
  display: none;
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  position: relative;
  padding: 10px;
  font-family: Rubik, sans-serif;
  z-index: 1;
  text-align: left;
}

.tippy-content a,
.tippy-content a:active,
.tippy-content a:hover {
  color: #fcfcfc;
  text-decoration: underline;
}

input[type="text"]:disabled {
  background: #f5f5f5;
}

.Toastify__toast {
  border-radius: 8px;
}